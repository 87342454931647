<template>
  <div>
    <b-card class="correlation-card custom-card">
      <b-row class="flex-nowrap d-flex align-items-center">
        <b-col cols="auto">
          <b-form-group class="mb-0" style="min-width: 300px">
            <DatasourceSelect v-model="datasourceSelected" :nullDisabled="true" mode="multiple" :disabled="showOverlay" :autoLocationSelected="autoLocationSelected" />
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <b-form-group class="mb-0">
            <OptionChartCorrelation :label="$t('option_chart')" :nameComponent="name" />
          </b-form-group>
        </b-col>
        <b-col cols="auto">
          <ButtonPushable :title="$t('click_to_calculate')" class="btn-execute" size="xxl" variant="success" @click.native="execute" :disabled="showOverlay">
            <div class="d-flex align-items-center" style="justify-content: center" v-if="showOverlay">
              <span style="margin-right: 4px" class="text">{{ $t('calculating') }} </span> <b-spinner small />
            </div>
            <span v-else class="text">{{ $t('execute') }}</span>
          </ButtonPushable>
        </b-col>
        <b-col cols="auto" class="ml-auto d-flex align-items-center">
          <SummaryData :chartData="chartData" />
          <Downloader ref="refDownload" :downloadImage="downloadImage" :downloadCsv="downloadCsv" label="correlation" />
        </b-col>
      </b-row>
    </b-card>

    <b-overlay :show="showOverlay" variant="transparent" opacity="0.85" blur="2px" rounded="sm">
      <CorrelationChart :chart="chart" id="result-container" />
    </b-overlay>

    <Timeplayer ref="timeplayer" v-model="date" :liveMode="liveMode" modeValue="range" :playable="false" fpsModeValue="ms" :moveToEnd="moveToEnd" />
    <AutoGroups :groupData="autoGroupData" :autoGroup="autoGroup" />
  </div>
</template>

<script>
const _ = require('lodash')
import SelectDatasourceMixin from '@/mixins/SelectDatasourceMixin'
import Timeplayer from '../../common/Timeplayer.vue'
import DatasourceSelect from '../../common/DatasourceSelect/DatasourceSelect.vue'
import ButtonPushable from '../../common/ButtonPushable.vue'
import Downloader from '../../common/Downloader.vue'
import SummaryData from '../../common/SummaryData.vue'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import CorrelationChart from './CorrelationChartComponent.vue'
import AutoGroups from '../../common/AutoGroups.vue'
import RadioGroup from '../../common/RadioGroup.vue'
import OptionChartCorrelation from '../../common/OptionChartCorrelation.vue'
import { ThemeConfig } from '@/mixins/ThemeMixin.js'
import { SelectedGroupMixin } from '@/mixins/GroupItemsMixin'
import { splitByLastIndex } from '@/utilities/StringUtility.js'
import { replaceNaWithForwardValue, replaceNaWithBackwardValue, replaceNaWithZero, interpolateArray, interpolateArraywithLinear } from '@/utilities/NumberUtility.js'
import { OPTION_PLOTLY_PALETTE } from '@/constants/colors'
import { CORRELATION_API } from '@/constants/urls'
export default {
  name: 'correlation',
  components: { CorrelationChart, Timeplayer, DatasourceSelect, Downloader, SummaryData, ButtonPushable, AutoGroups, RadioGroup, OptionChartCorrelation },
  mixins: [SelectDatasourceMixin, ThemeConfig, SelectedGroupMixin, ToastificationContent],
  data() {
    return {
      xlsxData: null,
      showOverlay: false,
      moveToEnd: 0,
      autoLocationSelected: [],
      unselectLocation: [],
    }
  },
  created() {
    let autoGroup = _.cloneDeep(this.autoGroup)
    if (autoGroup) {
      this.autoLocationSelected = {
        select: this.getDataLocationItemByGroup(this.autoGroupData[autoGroup]),
      }
    }
  },
  computed: {
    name() {
      return this.$options.name
    },
    date: {
      get() {
        return this.$store.state.tabs[this.$options.name].date
      },
      set(date) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { date })
      },
    },
    chart: {
      get() {
        return this.$store.state.tabs[this.$options.name].chart
      },
      set(chart) {
        this.$store.commit(`tabs/SET_${this.$options.name.toUpperCase()}`, { chart })
      },
    },
    selectedGroup() {
      return this.$store.state.tabs.correlation.selectedGroup
    },
    chartData() {
      let chartData = []
      for (const item of this.correlationData) {
        chartData = chartData.concat(item[1])
      }
      return chartData
    },
    tempProject() {
      return this.$store.state.ecoplot.tempProject
    },

    method() {
      return this.$store.state.tabs[this.$options.name].method
    },
    autoGroup() {
      return this.$store.state.tabs.correlation.autoGroup
    },
    datasources() {
      return this.$store.state.datasource.datasources.filter((d) => d.type === 'timeseries')
    },
    autoGroupData() {
      let result = {}
      for (let i = 0; i < this.datasources.length; i++) {
        const metadata = this.$db[this.datasources[i].id]
        if (!metadata.autoGroups) continue
        const autoGroupKeys = Object.keys(metadata.autoGroups)
        autoGroupKeys.map((key) => {
          if (!result[key]) result[key] = metadata.autoGroups[key].map((value) => value.toString().concat(`|${this.datasources[i].id}`))
          else result[key] = result[key].concat(metadata.autoGroups[key].map((value) => value.toString().concat(`|${this.datasources[i].id}`)))
        })
      }
      return result
    },
    autoScale() {
      return this.$store.state.tabs[this.$options.name].autoScale
    },
    markerColor() {
      return OPTION_PLOTLY_PALETTE.D3
    },
    liveMode() {
      try {
        let check = false
        if (!this.datasources || !this.datasourceSelected) return false
        let arryIdDatasources = []
        const datasources = this.$store.state.datasource.datasources
        datasources.map((data) => {
          arryIdDatasources.push(data.id)
        })
        arryIdDatasources = _.unionBy(arryIdDatasources)
        this.datasourceSelected.map((data) => {
          let indexData = arryIdDatasources.indexOf(data.datasource)
          if (indexData > -1 && datasources[indexData].live) {
            check = true
            return check
          }
        })
        return check
      } catch {}
      return false
    },
    countLiveMode() {
      return this.$store.state.settings.countLiveMode
    },
  },
  asyncComputed: {
    correlationData: {
      default: [],
      async get() {
        let traces = []
        try {
          // min date and max date (range case and single case)
          const minDate = this.date.length === 2 ? this.date[0] : this.date.length === 1 ? '0000-00-00 00:00:00' : ''
          const maxDate = this.date.length === 2 ? this.date[1] : this.date.length === 1 ? this.date[0] : ''

          // Group by datasource
          const timeseriesDatasource = this.datasourceSelected.filter((ds) => ds.location)
          if (timeseriesDatasource.length !== 0) {
            const datasourceGrouped = _.groupBy(timeseriesDatasource, (d) => d.datasource)
            for (const datasourceId in datasourceGrouped) {
              const locationsItems = _.map(datasourceGrouped[datasourceId], (data) => {
                return data.location + '-' + data.item
              })
              const records = await this.selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, null)
              if (!records.dates) continue
              // Build traces and 'chartData'
              for (const locationItem of locationsItems) {
                const name = locationItem.replace('-', ' - ')
                const data = records[locationItem]
                traces.push([`${datasourceId}__${name}`, data])
              }
            }
          }
          if (timeseriesData['data'].length > 0) {
            for (let n = 0; n < timeseriesData['data'].length; n++) {
              traces.push([`${timeseriesData['fileNameDatasource'][n]}__${timeseriesData['lable'][n]}`, timeseriesData['data'][n].map(Number)])
            }
          }
        } catch {}
        return traces
      },
    },
  },
  watch: {
    datasourceSelected() {
      this.moveToEnd++
    },
    autoGroup: {
      deep: true,
      handler(newVal, oldVal) {
        if (!newVal && !oldVal) return
        if (oldVal && newVal === oldVal) {
          this.autoLocationSelected = {
            select: this.getDataLocationItemByGroup(this.autoGroupData[newVal]),
          }
        } else {
          this.autoLocationSelected = {
            unSelect: this.getDataLocationItemByGroup(this.autoGroupData[oldVal]),
            select: this.getDataLocationItemByGroup(this.autoGroupData[newVal]),
          }
        }
      },
    },
    countLiveMode() {
      if (this.selectedDatasources.length) {
        this.runExecutableCorrelation()
      } else {
        this.$toast({ component: ToastificationContent, props: { title: this.$t('calculated_failed'), text: this.$t('correlation_warning_time_steps'), icon: 'SlashIcon', variant: 'danger' } })
        this.chart = null
      }
    },
  },
  methods: {
    execute() {
      if (this.selectedDatasources.length) {
        this.runExecutableCorrelation()
      } else {
        this.$toast({ component: ToastificationContent, props: { title: this.$t('calculated_failed'), text: this.$t('correlation_warning_time_steps'), icon: 'SlashIcon', variant: 'danger' } })
        this.chart = null
      }
    },
    showChart(correData) {
      this.showOverlay = false
      this.endTime = new Date().getTime()
      this.$refs.timeplayer.setMs(this.endTime - this.startTime)
      if (correData.status === 'success') {
        this.xlsxData = correData.data.xlsx_data
        this.chart = `data:image/jpg;base64,${correData.data.image}`
      } else if (correData.status === 'error') {
        let txt = ''
        if (correData.message.startsWith('All arrays must be of the same length')) txt = this.$t('correlation_recommend_use_merge_tool')
        else if (correData.message.startsWith('Too many NA value')) txt = this.$t('correlation_recommend_use_NA_tool')
        else txt = correData.message
        this.$toast({ component: ToastificationContent, props: { title: txt === correData.message ? this.$t('calculated_failed') : correData.message, text: txt, icon: 'SlashIcon', variant: 'danger' } })
      }
    },
    async runExecutableCorrelation() {
      if (!this.showOverlay && this.selectedDatasources.length) {
        const resultContainer = document.getElementById('result-container')

        if (!this.date.length || this.date[0] === this.date[1]) {
          this.$toast({ component: ToastificationContent, props: { title: this.$t('calculated_failed'), text: this.$t('correlation_warning_time_steps'), icon: 'SlashIcon', variant: 'danger' } })
          this.showOverlay = false
          return
        }
        const correlationData = _.cloneDeep(this.correlationData)
        if (correlationData.length < 2) {
          this.$toast({ component: ToastificationContent, props: { title: this.$t('calculated_failed'), text: this.$t('correlation_warning_items'), icon: 'SlashIcon', variant: 'danger' } })
          this.showOverlay = false
          return
        }
        if (resultContainer) {
          this.showOverlay = true
          this.startTime = new Date().getTime()
          const allDatesLength = correlationData.map((d) => d[1].length)
          let dateLengthValid = null
          if (this.autoScale === 'trim') dateLengthValid = _.min(allDatesLength)
          else dateLengthValid = _.max(allDatesLength)

          const body = {
            data: Object.fromEntries(correlationData.map((d) => [d[0], this.correlationAutoScale(this.autoScale, d[1], dateLengthValid)])),

            width: resultContainer.clientWidth - 24 * 2,
            height: resultContainer.clientHeight - 24,
            theme: this.theme,
            method: this.method,
          }
          const response = await fetch(CORRELATION_API, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Token ${localStorage.getItem('django-authentication-token')}`,
            },
            body: JSON.stringify(body),
          })
          const correData = await response.json()

          this.showChart(correData)
        }
      }
    },
    downloadImage() {
      this.$refs.refDownload.download({
        filename: this.tempProject.name + '_correlation.png',
        data: this.chart,
      })
    },
    downloadCsv() {
      if (!this.xlsxData) return
      this.$refs.refDownload.download({
        filename: this.tempProject.name + '_correlation.xlsx',
        data: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + this.xlsxData,
      })
    },
    baseName(str) {
      var base = new String(str).substring(str.lastIndexOf('/') + 1)
      if (base.lastIndexOf('.') != -1) base = base.substring(0, base.lastIndexOf('.'))
      return base
    },
    getDataLocationItemByGroup(groupData) {
      if (!groupData) return []
      let result = []
      for (let i = 0; i < groupData.length; i++) {
        const [locationItems, datasourceId] = splitByLastIndex(groupData[i], '|')
        const [location, item] = splitByLastIndex(locationItems, '*')
        result.push({
          datasource: datasourceId,
          item,
          location,
        })
      }
      return result
    },
    correlationAutoScale(scale, data, dateLengthValid) {
      if (!scale) return data
      else if (scale === 'trim') return data.slice(0, dateLengthValid)
      else if (scale === 'zero') {
        data = replaceNaWithZero(data)
        return data.length < dateLengthValid ? data.concat(new Array(dateLengthValid - data.length).fill(0)) : data
      } else if (scale === 'forward') {
        data = replaceNaWithForwardValue(data)
        return data.length < dateLengthValid ? data.concat(new Array(dateLengthValid - data.length).fill(data[data.length - 1])) : data
      } else if (scale === 'backward') {
        data = replaceNaWithBackwardValue(data)
        if (data.length < dateLengthValid) {
          let arrayApply = new Array(dateLengthValid - data.length).fill(data[0])
          arrayApply = arrayApply.concat(data)
          return arrayApply
        } else {
          return data
        }
      } else if (scale === 'linear') {
        // data = interpolateArray(data, dateLengthValid);
        data = interpolateArraywithLinear(data, dateLengthValid)
        return data
      }
    },
  },
}
</script>

<style scoped>
.correlation-card {
  max-height: 90px;
}
.correlation-card .btn-execute {
  height: 62px;
  min-width: 165px;
}
.correlation-card .btn-execute .btn-pushable-front {
  height: 55px;
}
.correlation-card .btn-execute .text {
  font-size: 20px;
}
</style>
